import Card from './components/UI/Card';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './components/pages/home/home';
import TBX from './components/pages/TBX/TBX';
import Secutec from './components/pages/Secutec/Secutec';
import Cybersec from './components/pages/cybersec/cybersec';

function App() {
  return (
    <div className="text-white">
      <Card>
      <Router>
        <Route path="//" component={Home} />
        <Route path="/TBX" component={TBX} />
        <Route path="/TBX2021" component={TBX} />
        <Route path="/Secutec" component={Secutec} />
        <Route path="/Cybersec" component={Cybersec} />
        <Route path="/Cybersec2022" component={Cybersec} />

        </Router>

        </Card>
    </div>
  );
}

export default App;
