import React from 'react';
import simonemoji from '../../images/simonemoji.png';
import Card from '../../UI/Card';
import Typewriter from 'typewriter-effect';
import './home.css';

const Home = () => {
    return (
        <div className="d-flex flex-column justify-content-center text-center">
            <div className="cardheader">
                <Typewriter onInit={(typewriter) => {
                    typewriter.typeString("Hello!").pauseFor(2500).typeString(" Welcome!").pauseFor(2500).deleteAll()
                        .typeString("Just click something").pauseFor(2500).deleteAll().pauseFor(2500).start();
                }} options={{
                    autoStart: true,
                    loop: true,
                    wrapperClassName: 'text-center justify-content-center fs-2',
                    cursorClassName: 'text-center justify-content-center fs-2'
                }} />
                <div><img src={simonemoji} alt="Simon's emoji" className="emoji" /></div></div>
            <div class="d-flex flex-column justify-content-center text-center buttonarea">
                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://www.linkedin.com/in/wijckmans/';
                }}>LinkedIn</button>
                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://twitter.com/SimonWijckmans';
                }}>Twitter</button>
                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://simonwijckmans.com';
                }}>Website</button>
            </div>

        </div>);
};

export default Home; 