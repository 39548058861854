import React from 'react';
import cs_logo from '../../images/Cybersec.png';

import Typewriter from 'typewriter-effect';
import './cybersec.css';

const Cybersec = () => {
    return (
        <div className="d-flex flex-column justify-content-center text-center">
            <div className="cardheader">
                <div class="">
                    <Typewriter onInit={(typewriter) => {
                        typewriter.typeString("Liked what you saw?").pauseFor(2500).deleteAll()
                            .typeString("Got feedback?").pauseFor(2500).deleteAll()
                            .typeString("LET US KNOW").pauseFor(2500).deleteAll().start();
                    }} options={{
                        autoStart: true,
                        loop: true,
                        wrapperClassName: 'text-center justify-content-center fs-2',
                        cursorClassName: 'text-center justify-content-center fs-2'
                    }} /></div>
                <div class="TBXlogo"><img src={cs_logo} alt="Cybersec logo" className="img-fluid center-block" /></div></div>
            <div class="d-flex flex-column justify-content-center text-center buttonarea">
                <h1>Press the buttons below</h1>
                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.open('https://twitter.com/intent/tweet?text=I%20just%20watched%20%40SimonWijckmans%20presentation%20about%20%40Cloudflare%20at%20%40CyberSec2022%20and%20absolutely%20loved%20it!', '_blank');
                }}>Share some ❤️ on Twitter</button>

                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.cloudflare.com/tbx2021/', '_blank');
                }}>Cloudflare docs</button>

                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://www.linkedin.com/in/wijckmans/';
                }}>Connect on LinkedIn</button>
                <button type="button" class="btn btn-secondary" onClick={(e) => {
                    e.preventDefault();
                    window.open('https://simonflare.com/feedback', '_blank');
                }}>Feedback form</button>
            </div>

        </div>);
};

export default Cybersec; 